import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { EventContext } from '../Contexts/Events';
import { ReactComponent as IconArrow } from '../assets/images/arrow.svg';
import Busy from '../Components/Busy';
import Modal from '../Components/Modal';

function Resources() {
    const eventHandler = useContext(EventContext);
    const loading = useRef(true);

    const [resourceState, setResourceState] = useState({
        busy: true,
        resources: {} as any,
        error: false as any
    });

    const formatResources = (resources: any) => {
        let output: any = {};

        resources.forEach((resource: any) => {
            resource.categories.forEach((category: any) => {
                if (!(category.name in output)) {
                    output[category.name] = [];
                }
                output[category.name].push(resource);
            })
        });

        return output;
    }

    useEffect(() => {
        const receiveResources = (resources: any) => {
            setResourceState({
                ...resourceState,
                busy: false,
                resources: formatResources(resources)
            });
        }
        if (loading.current) {
            eventHandler.trigger('resources:request');
            loading.current = false;
        }

        eventHandler.on('resources:receive', receiveResources);
        return () => {
            eventHandler.off('resources:receive', receiveResources);
        }
    }, [eventHandler, resourceState]);

    return (
        <div className={`Resources ${resourceState.busy ? 'is--busy' : ''}`}>
            {resourceState.error !== false && (
                <Modal onDismiss={() => setResourceState({ ...resourceState, error: false })} title={resourceState.error.title}>{resourceState.error.message}</Modal>
            )}
            <h1>Resources</h1>
            {resourceState.busy && (
                <Busy />
            )}
            {!resourceState.busy && (
                <>
                    <div className="ResourcesList">
                        {Object.keys(resourceState.resources).length === 0 && (
                            <div className="ResourcesList--Empty">No resources.</div>
                        )}
                        {Object.keys(resourceState.resources).map((category: any) => {
                            return (
                                <React.Fragment key={category}>
                                    <h2>{category}</h2>
                                    {resourceState.resources[category].map((resource: any) => {
                                        return (
                                            <button key={resource.id} onClick={() => window.open(resource.url, '')} className="ResourcesListItem">
                                                <strong>{resource.name}</strong>
                                                <span><IconArrow width={15.91} height={24.6} /></span>
                                            </button>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    )
}

export default Resources;
